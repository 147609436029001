<template>
  <div>
    <v-carousel
        show-arrows-on-hover
        hide-delimiters
        interval="1000"
        class="rounded-lg"
        height="265">
      <v-carousel-item
          v-for="(image, index) in images"
          :key="index"
          :src="image"
      >
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  name: "Gallery",
  props: {
    images: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }
}
</script>

<style scoped>

</style>